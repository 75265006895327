import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import ReportDeathModal from '#/components/shared/ReportDeathModal';

export default function ReportDeath({
  reportedAsDead,
}: {
  reportedAsDead: boolean;
}) {
  const { translate } = useLocales();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <Button onClick={handleOpenModal} disabled={reportedAsDead}>
        <Typography>
          {reportedAsDead
            ? String(translate('guest.alreadyReported'))
            : String(translate('guest.reportDeath'))}
        </Typography>
      </Button>
      <ReportDeathModal open={openModal} onClose={handleCloseModal} />
    </>
  );
}
