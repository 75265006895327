import { useMemo } from 'react';

interface AccessDetails {
  specific_service_access?: {
    service: string;
    accessLevel: 'while-alive' | 'after-death';
  }[];
}

interface UseAccessParams {
  serviceAccessKeys: string[];
  generalAccess: 'while-alive' | 'after-death';
  hasSpecificAccess: boolean;
  userReportedDead: boolean;
  accessDetails?: AccessDetails;
}

const useServiceAccess = ({
  serviceAccessKeys,
  generalAccess,
  hasSpecificAccess,
  userReportedDead,
  accessDetails,
}: UseAccessParams): Record<string, boolean> => {
  const accessResults = useMemo(() => {
    const findAccessLevel = (serviceKey: string) =>
      accessDetails?.specific_service_access?.find(
        (access) => access.service === serviceKey
      )?.accessLevel;

    return serviceAccessKeys.reduce(
      (acc, serviceKey) => {
        let hasAccess = true;

        if (generalAccess === 'while-alive') {
          hasAccess =
            !hasSpecificAccess || findAccessLevel(serviceKey) !== 'after-death';
        } else if (generalAccess === 'after-death') {
          if (!userReportedDead) {
            hasAccess =
              hasSpecificAccess &&
              findAccessLevel(serviceKey) === 'while-alive';
          } else {
            hasAccess = !hasSpecificAccess;
          }
        }

        return { ...acc, [serviceKey]: hasAccess };
      },
      {} as Record<string, boolean>
    );
  }, [
    serviceAccessKeys,
    generalAccess,
    hasSpecificAccess,
    userReportedDead,
    accessDetails,
  ]);

  return accessResults;
};

export default useServiceAccess;
