import { useNavigate } from 'react-router-dom';
import Page from '#/components/shared/ui/Page';
import useAccountSwitch from '#/hooks/useAccountSwitch';
import useAuth from '#/hooks/useAuth';
import SuccessorDashboard from '#/components/pages/SuccessorDashboard';

export default function GuestDashboard() {
  const { user } = useAuth();
  const { currentAccount } = useAccountSwitch();
  const navigate = useNavigate();

  if (user?.id === currentAccount?.id && user?.primary_account) {
    navigate('/dashboard/home');
  }

  return (
    <Page
      title={`Gast: ${currentAccount?.first_name} ${currentAccount?.last_name}`}
    >
      <SuccessorDashboard />
    </Page>
  );
}
