import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  DownloadSignedDocument,
  ViewService,
} from '#/components/pages/SuccessorDashboard/extras/card-actions';
import Iconify from '#/components/shared/ui/Iconify';
import MenuPopover from '#/components/shared/ui/MenuPopover';
import { GoToHistory } from '#/components/pages/Dashboard/extras/service-actions';

type ExtrasProps = {
  serviceDoc: string;
  docType?: boolean;
  viewURL?: string;
  ownerID?: string;
  disabled?: boolean;
  historyURL?: string;
  historyAPI?: string;
  access: string;
};

export default function Extras({
  serviceDoc,
  docType,
  viewURL,
  ownerID,
  disabled,
  historyURL,
  historyAPI,
  access,
}: ExtrasProps) {
  const [moreOptions, setMoreOptions] = useState<HTMLElement | null>(null);
  const location = useLocation();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMoreOptions(event.currentTarget);
  };

  const handleClose = () => {
    setMoreOptions(null);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      {docType && (
        <DownloadSignedDocument disabled={disabled} downloadLink={serviceDoc} />
      )}
      {viewURL && (
        <ViewService
          disabled={disabled}
          toServiceURL={viewURL}
          ownerID={ownerID}
        />
      )}
      {historyURL && (
        <Button
          disabled={disabled}
          sx={{ borderRadius: 50, justifyContent: 'flex-end' }}
          onClick={handleOpen}
        >
          <Iconify
            icon="entypo:dots-three-vertical"
            color={disabled ? 'disabled' : '#000'}
          />
        </Button>
      )}
      <MenuPopover
        open={Boolean(moreOptions)}
        anchorEl={moreOptions}
        onClose={handleClose}
      >
        {historyURL && (
          <GoToHistory
            ownerID={ownerID}
            toServiceURL={location.pathname + historyURL}
            historyAPI={historyAPI}
            documentType={access}
          />
        )}
      </MenuPopover>
    </Stack>
  );
}
