interface ServiceCard {
  title: string;
  icon: string;
  description: string;
  serviceKey?: string;
  accessKey?: string;
  historyURL?: string;
  docType?: boolean;
  viewURL?: string;
  historyAPI?: string;
}

const sserviceCards: ServiceCard[] = [
  {
    title: 'global.dashCardInfos.livingWill.title',
    icon: '/assets/icons/ic-living-will.svg',
    description: 'global.dashCardInfos.livingWill.description',
    serviceKey: 'last_signed_living_will',
    historyURL: '/dokumentverlauf',
    accessKey: 'livingWill',
    docType: true,
    historyAPI: 'living-will',
  },
  {
    title: 'global.dashCardInfos.powerOfAttorney.title',
    icon: '/assets/icons/ic-power-of-attorney.svg',
    description: 'global.dashCardInfos.powerOfAttorney.description',
    serviceKey: 'last_signed_power_of_attorney',
    historyURL: '/dokumentverlauf',
    accessKey: 'powerOfAttorney',
    docType: true,
    historyAPI: 'power-of-attorney',
  },
  {
    title: 'global.dashCardInfos.lastWill.title',
    icon: '/assets/icons/ic-last-will.svg',
    description: 'global.dashCardInfos.lastWill.description',
    serviceKey: 'last_signed_last_will',
    historyURL: '/dokumentverlauf',
    accessKey: 'lastWill',
    docType: true,
    historyAPI: 'last-will/get',
  },
  {
    title: 'global.dashCardInfos.marriageContract.title',
    icon: '/assets/icons/ic-marriage-contract.svg',
    description: 'global.dashCardInfos.marriageContract.description',
    serviceKey: 'last_signed_marriage_contract',
    historyURL: '/dokumentverlauf',
    accessKey: 'marriageContract',
    docType: true,
    historyAPI: 'marriage-contract/get',
  },
  {
    title: 'global.dashCardInfos.assetsRegister.title',
    icon: '/assets/icons/ic-heirs-register.svg',
    description: 'global.dashCardInfos.assetsRegister.description',
    viewURL: '/dashboard/vermoegens-und-sachregister',
    accessKey: 'assetsRegister',
  },
  {
    title: 'global.dashCardInfos.digitalFootprint.title',
    icon: '/assets/icons/ic-digital-footprint.svg',
    description: 'global.dashCardInfos.digitalFootprint.description',
    viewURL: '/dashboard/digitaler-nachlass',
    accessKey: 'digitalFootprint',
  },
];

export default sserviceCards;
