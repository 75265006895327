import React from 'react';
import { Box } from '@mui/material';
import serviceCards from '#/components/pages/SuccessorDashboard/serviceCards';
import DashboardCard from '#/components/shared/dashboard-card';
import Extras from '#/components/pages/SuccessorDashboard/extras/extras';
import useLocales from '#/hooks/useLocales';
import useAccountSwitch from '#/hooks/useAccountSwitch';
import useServiceAccess from '#/lib/hooks/useServiceAccess';

export default function SServicesList() {
  const { translate } = useLocales();
  const { currentAccount } = useAccountSwitch();

  const serviceAccessKeys = serviceCards.map(
    (service) => service.accessKey as string
  );
  const accessDetails = currentAccount?.invitation_details;
  const generalAccess = accessDetails?.general_access_right;
  const hasSpecificAccess = accessDetails?.has_specific_access;
  const userReportedDead =
    currentAccount?.docs?.reported_as_dead_status === 'APPROVED';

  const accessResults = useServiceAccess({
    serviceAccessKeys,
    generalAccess,
    hasSpecificAccess,
    userReportedDead,
    accessDetails,
  });

  interface ServiceAccess {
    service: string;
  }

  const handleConsultantAccess = (service: string): boolean => {
    if (!hasSpecificAccess) return true;

    return (
      accessDetails?.specific_service_access?.some(
        (access: ServiceAccess) => access.service === service
      ) ?? false
    );
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: 2,
      }}
    >
      {serviceCards.map((card, i) => (
        <DashboardCard
          title={card.title}
          icon={card.icon}
          description={card.description}
          key={`${card.title + i}`}
          tooltipTitle={String(
            translate('global.dashCardInfos.guest.disabledButton')
          )}
          showTooltip={!accessResults[card.accessKey as string]}
          actions={
            <Extras
              access={card.accessKey as string}
              serviceDoc={
                currentAccount?.docs
                  ? currentAccount?.docs[card.serviceKey as string]
                  : ''
              }
              {...card}
              ownerID={currentAccount?.id}
              disabled={
                accessDetails?.invitation_type === 'CONSULTANT_ACCESS'
                  ? !handleConsultantAccess(card.accessKey as string) ||
                    currentAccount?.docs[card.serviceKey as string] === null
                  : !accessResults[card.accessKey as string] ||
                    currentAccount?.docs[card.serviceKey as string] === null
              }
            />
          }
        />
      ))}
    </Box>
  );
}
