import { Box } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import DashboardContainer from '#/components/shared/dashboard-container';
import useAccountSwitch from '#/hooks/useAccountSwitch';
import ReportDeath from '#/components/pages/SuccessorDashboard/report-death';
import SServicesList from '#/components/pages/SuccessorDashboard/services-list';

export default function SuccessorDashboard() {
  const { translate } = useLocales();
  const { currentAccount } = useAccountSwitch();

  return (
    <DashboardContainer
      introTitle={String(
        translate('guest.title', {
          first_name: currentAccount?.first_name,
          last_name: currentAccount?.last_name,
        })
      )}
      introDescription={String(translate('guest.description'))}
      sx={{
        '& > *': {
          mb: 10,
        },
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <ReportDeath
          reportedAsDead={currentAccount?.docs?.reported_as_dead as boolean}
        />
      </Box>
      <SServicesList />
    </DashboardContainer>
  );
}
